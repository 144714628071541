import {useGoVehisService} from "../../../utils/axios";
import React, {useEffect, useState} from "react";
import {Box, Button, MenuItem, Select, Table, TableBody, TextField} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import "./AttributesList.css";
import DeleteIcon from "@material-ui/icons/Delete";
import Checkbox from "@material-ui/core/Checkbox";
import EditIcon from "@material-ui/icons/Edit";
import CopyIcon from "@material-ui/icons/FileCopy";
import TableContainer from "@material-ui/core/TableContainer";

const classList = [
  "Lekkie dostawcze",
  "Mikro",
  "Małe",
  "Luksusowe",
  "Executive",
  "Wyższa średnia",
  "Niższa średnia",
  "Średnie dostawcze",
  "Ciężkie dostawcze"
]

const segmentList = [
  "Duże dostawcze",
  "Kombivany",
  "Kompaktowe / średnie",
  "Limuzyny / duże",
  "Luksusowe",
  "Małe dostawcze",
  "Miejskie / małe",
  "Pick-up",
  "Sportowe",
  "SUVy / terenowe duże",
  "SUVy / terenowe małe",
  "SUVy / terenowe średnie",
  "Średnie dostawcze",
  "Vany duże / minibusy",
  "Vany średnie"
]

const typeList = [
  "DOSTAWCZE",
  "OSOBOWE"
]

const internalBodyTypeList = [
  "Autolaweta",
  "Chłodnia/Izoterma",
  "Coupe / Roadster",
  "Do Zabudowy",
  "Doka",
  "Furgon",
  "Hatchback",
  "Inne",
  "Kabriolet",
  "Kamper",
  "Kombi",
  "Kontener",
  "Osobowy",
  "Pick-Up",
  "Podnośnik",
  "Sedan",
  "Skrzynia",
  "Skrzynia z plandeką",
  "Skrzynia z wywrotką",
  "SUV / Crossover",
  "VAN / Bus"
]

let initFilter = {
  brand: "",
  model: "",
  modelCode: null,
  bodyType: "",
  classType: "",
  segment: "",
  type: "",
  premium: null,
  width: null,
  length: null,
  height: null,
  trunkCapacity: null,
  internalBodyType: ""
}

function AttributesList() {
  const goVehisService = useGoVehisService();

  const [attributesElements, setAttributeElements] = useState([]);
  const [createdIndex, setCreatedIndex] = useState(-1);
  const [filter, setFilter] = useState(initFilter);

  useEffect(() => {
    goVehisService.get("/admin/config/attributes").then(
      res => {setAttributeElements(res.data);}
    )
  }, [])

  const addRow = () => {
    let index = createdIndex;
    setCreatedIndex(createdIndex - 1);
    let newElement = {
      id: index,
      brand: "",
      model: "",
      modelCode: "",
      bodyType: "",
      classType: "",
      segment: "",
      type: "",
      premium: false,
      length: null,
      width: null,
      height: null,
      trunkCapacity: null,
      internalBodyType: "",
      deleted: false,
      edited: false,
      created: true
    }
    setAttributeElements([newElement, ...attributesElements]);
  }

  const copyRow = (row) => {
    let index = createdIndex;
    setCreatedIndex(createdIndex - 1);
    let newElement = {
      id: index,
      brand: row.brand,
      model: row.model,
      modelCode: row.modelCode,
      bodyType: row.bodyType,
      classType: row.classType,
      segment: row.segment,
      type: row.type,
      premium: row.premium,
      length: row.length,
      width: row.width,
      height: row.height,
      trunkCapacity: row.trunkCapacity,
      internalBodyType: row.internalBodyType,
      deleted: false,
      edited: false,
      created: true
    }
    setAttributeElements([newElement, ...attributesElements]);
  }

  const handleChange = (e, id, fieldName) => {
    const newAttributesElements = [...attributesElements];
    let index = attributesElements.findIndex(el => el.id === id);
    switch(fieldName) {
      case "brand": newAttributesElements[index].brand = e.target.value; break;
      case "model": newAttributesElements[index].model = e.target.value; break;
      case "modelCode": newAttributesElements[index].modelCode = e.target.value; break;
      case "bodyType": newAttributesElements[index].bodyType = e.target.value; break;
      case "classType": newAttributesElements[index].classType = e.target.value; break;
      case "segment": newAttributesElements[index].segment = e.target.value; break;
      case "type": newAttributesElements[index].type = e.target.value; break;
      case "premium": newAttributesElements[index].premium = e.target.checked; break;
      case "length": newAttributesElements[index].length = e.target.value; break;
      case "width": newAttributesElements[index].width = e.target.value; break;
      case "height": newAttributesElements[index].height = e.target.value; break;
      case "trunkCapacity": newAttributesElements[index].trunkCapacity = e.target.value; break;
      case "internalBodyType": newAttributesElements[index].internalBodyType = e.target.value; break;
    }
    setAttributeElements(newAttributesElements);
    // console.log(attributesElements);
  };

  const handleChangeFilter = (e, fieldName) => {

  }

  const deleteRow = (id) => {
    const newAttributesElements = [...attributesElements];
    let index = attributesElements.findIndex(el => el.id === id);
    if(newAttributesElements[index].created) {
      newAttributesElements.splice(index, 1);
    } else {
      newAttributesElements[index].deleted = !newAttributesElements[index].deleted;
    }
    setAttributeElements(newAttributesElements);
  }

  const editRow = (id) => {
    const newAttributesElements = [...attributesElements];
    let index = attributesElements.findIndex(el => el.id === id);
    newAttributesElements[index].edited = true;
    setAttributeElements(newAttributesElements);
  }

  const calculateColor = (el) => {
    if(el.deleted) {
      return "#ffb7b7";
    } else if(el.created) {
      return "#e3ffe3";
    } else if(el.edited) {
      return "#fffad2";
    }
    return "transparent";
  }

  const sendChanges = () => {
    const list = [...attributesElements];
    const changedElements = list.filter(el => el.edited && !el.deleted).map(toCleanObject);
    const createdELements = list.filter(el => el.created).map(toCleanObject);
    const deletedIds = list.filter(el => el.deleted).map(el => el.id);
    const allData = {
      createdList: createdELements,
      updatedList: changedElements,
      deletedIds: deletedIds
    };
    goVehisService.post("/admin/config/attributes", allData).then(r => location.reload())
  }

  const toCleanObject = (el) => {
    return {
      id: el.id,
      brand: el.brand,
      model: el.model,
      modelCode: el.modelCode,
      bodyType: el.bodyType,
      classType: el.classType,
      segment: el.segment,
      type: el.type,
      premium: el.premium,
      length: el.length,
      width: el.width,
      height: el.height,
      trunkCapacity: el.trunkCapacity,
      internalBodyType: el.internalBodyType
    }

  }

  return (
    <div>
      <Box p={2} position="sticky">
        <Button onClick={e => addRow()} variant="contained" style={{margin: 3}}>Dodaj wiersz</Button>
        <Button onClick={e => sendChanges()} variant="contained" color="primary" style={{margin: 3}}>Zapisz</Button>
      </Box>
      <TableContainer sx={{maxHeight: 600}} className="attributes-container">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Id</TableCell>
              <TableCell>Marka</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Model_code</TableCell>
              <TableCell>Typ nadwozia - vehis</TableCell>
              <TableCell>Klasa</TableCell>
              <TableCell>Segment</TableCell>
              <TableCell>Typ</TableCell>
              <TableCell>Premium</TableCell>
              <TableCell>Długość</TableCell>
              <TableCell>Szerokość</TableCell>
              <TableCell>Wysokość</TableCell>
              <TableCell>Pojemność</TableCell>
              <TableCell>Nadwozie go-auto</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              attributesElements.map((el) => (
                <React.Fragment>
                  { (el.deleted || !el.created && !el.edited) &&
                    <TableRow key={el.id} style={{backgroundColor:  calculateColor(el)}}>
                      <TableCell><DeleteIcon onClick={e => deleteRow(el.id)}/> {!el.created && !el.edited && <EditIcon onClick={e => editRow(el.id)}/>} <CopyIcon onClick={e => copyRow(el)}/></TableCell>
                      <TableCell>{el.id} </TableCell>
                      <TableCell>{el.brand}</TableCell>
                      <TableCell>{el.model}</TableCell>
                      <TableCell>{el.modelCode}</TableCell>
                      <TableCell>{el.bodyType}</TableCell>
                      <TableCell>{el.classType}</TableCell>
                      <TableCell>{el.segment}</TableCell>
                      <TableCell>{el.type}</TableCell>
                      <TableCell>{el.premium ? "TAK" : "NIE"}</TableCell>
                      <TableCell>{el.length}</TableCell>
                      <TableCell>{el.width}</TableCell>
                      <TableCell>{el.height}</TableCell>
                      <TableCell>{el.trunkCapacity}</TableCell>
                      <TableCell>{el.internalBodyType}</TableCell>

                    </TableRow>
                  }
                  { !el.deleted && (el.edited || el.created) &&
                  <TableRow key={el.id} style={{backgroundColor:  calculateColor(el)}}>
                    <TableCell><DeleteIcon onClick={e => deleteRow(el.id)}/></TableCell>
                    <TableCell>{el.id} </TableCell>
                    <TableCell><TextField defaultValue={el.brand} fullWidth onBlur={e => handleChange(e, el.id, "brand")} variant="outlined"/></TableCell>
                    <TableCell><TextField defaultValue={el.model} fullWidth onBlur={e => handleChange(e, el.id, "model")} variant="outlined"/></TableCell>
                    <TableCell><TextField defaultValue={el.modelCode} fullWidth onBlur={e => handleChange(e, el.id, "modelCode")} variant="outlined"/></TableCell>
                    <TableCell><TextField defaultValue={el.bodyType} fullWidth onBlur={e => handleChange(e, el.id, "bodyType")} variant="outlined"/></TableCell>
                    <TableCell><Select defaultValue={el.classType} fullWidth onChange={e => handleChange(e, el.id, "classType")} variant="outlined">
                      <MenuItem value={null}>Nie wybrano</MenuItem>
                      {classList.map((p) => <MenuItem value={p}>{p}</MenuItem>)}
                    </Select></TableCell>
                    <TableCell><Select defaultValue={el.segment} fullWidth onChange={e => handleChange(e, el.id, "segment")} variant="outlined">
                      <MenuItem value={null}>Nie wybrano</MenuItem>
                      {segmentList.map((p) => <MenuItem value={p}>{p}</MenuItem>)}
                    </Select></TableCell>
                    <TableCell><Select defaultValue={el.type} fullWidth onChange={e => handleChange(e, el.id, "type")} variant="outlined">
                      <MenuItem value={null}>Nie wybrano</MenuItem>
                      {typeList.map((p) => <MenuItem value={p}>{p}</MenuItem>)}
                    </Select></TableCell>
                    <TableCell><Checkbox checked={el.premium} fullWidth onChange={e => handleChange(e, el.id, "premium")} variant="outlined"/></TableCell>
                    <TableCell><TextField defaultValue={el.length} fullWidth onBlur={e => handleChange(e, el.id, "length")} variant="outlined"/></TableCell>
                    <TableCell><TextField defaultValue={el.width} fullWidth onBlur={e => handleChange(e, el.id, "width")} variant="outlined"/></TableCell>
                    <TableCell><TextField defaultValue={el.height} fullWidth onBlur={e => handleChange(e, el.id, "height")} variant="outlined"/></TableCell>
                    <TableCell><TextField defaultValue={el.trunkCapacity} fullWidth onBlur={e => handleChange(e, el.id, "trunkCapacity")} variant="outlined"/></TableCell>
                    <TableCell><Select defaultValue={el.internalBodyType} fullWidth onChange={e => handleChange(e, el.id, "internalBodyType")} variant="outlined">
                      <MenuItem value={null}>Nie wybrano</MenuItem>
                      {internalBodyTypeList.map((p) => <MenuItem value={p}>{p}</MenuItem>)}
                    </Select></TableCell>
                  </TableRow>}

                </React.Fragment>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default AttributesList;
