import {Helmet} from "react-helmet";
import React, {useState} from "react";
import axios from 'axios';
import {Button, Container, Typography, Box, TextField, Grid, CircularProgress} from '@material-ui/core';
import {useGoVehisService} from "../utils/axios";

export default function LeasingMatrix() {

  const [file, setFile] = useState(null);
  const [downloading, setDownloading] = useState(false); // State for handling download loading
  const [uploading, setUploading] = useState(false); // State for handling upload loading
  const goVehisService = useGoVehisService();

  // Handle file selection
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  // Handle downloading the matrix file
  const handleDownloadMatrix = async () => {
    setDownloading(true); // Set loading state to true
    let credentials = await goVehisService.get("/admin/proxy/leasingOnline/credentials");
    try {
      const response = await axios.get(process.env.REACT_APP_LEASING_ONLINE_URL + '/admin/kstElements/xlsx', {
        responseType: 'blob',
        auth: {
          username: credentials.data.username,
          password: credentials.data.password
        }
      });

      // Create a URL for the file and download it
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'kst_elements.xlsx'); // Specify the filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the link after downloading
    } catch (error) {
      console.error('Error downloading the file', error);
    } finally {
      setDownloading(false); // Reset loading state
    }
  };

  // Handle uploading the matrix file
  const handleUploadMatrix = async () => {
    if (!file) {
      alert("Please select a file to upload!");
      return;
    }

    setUploading(true); // Set loading state to true
    const formData = new FormData();
    formData.append('file', file);

    try {
      let credentials = await goVehisService.get("/admin/proxy/leasingOnline/credentials");
      await axios.post(process.env.REACT_APP_LEASING_ONLINE_URL + '/admin/kstElements/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        auth: {
          username: credentials.data.username,
          password: credentials.data.password
        }
      });
      alert('Matryca wprowadzona poprawnie');
    } catch (error) {
      console.error('Error uploading the file', error);
      alert('Failed to upload the file');
    } finally {
      setUploading(false); // Reset loading state
    }
  };

  return (
    <div>
      <Helmet>
        <title>Matryca modeli | GO-auto - Panel administracyjny</title>
      </Helmet>

      <Container>
        <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
          <Typography variant="h4" gutterBottom>
            Matryca KŚT - leasing online
          </Typography>

          <Grid container spacing={2} justifyContent="center" alignItems="stretch">
            {/* Left Column: Download */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  padding: '20px',
                  textAlign: 'center',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%', // Ensure full height
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Pobierz matrycę Excel
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDownloadMatrix}
                  disabled={downloading} // Disable button when downloading
                  fullWidth
                >
                  {downloading ? <CircularProgress size={24} /> : 'Pobierz matrycę Excel'}
                </Button>
              </Box>
            </Grid>

            {/* Right Column: Upload */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  padding: '20px',
                  textAlign: 'center',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%', // Ensure full height
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Prześlij matrycę
                </Typography>
                <TextField
                  type="file"
                  inputProps={{ accept: ".xlsx" }}
                  onChange={handleFileChange}
                  fullWidth
                  sx={{ marginBottom: '10px' }}
                  disabled={uploading} // Disable file input when uploading
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleUploadMatrix}
                  disabled={!file || uploading} // Disable button when no file or uploading
                  fullWidth
                >
                  {uploading ? <CircularProgress size={24} /> : 'Prześlij'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  )
}
