import React, {useEffect, useMemo, useState} from "react";
import {FormControl, InputAdornment, InputLabel, ListSubheader, Select, TextField} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {useGoVehisService} from "../../../utils/axios";


const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;


export default function GuardianSelectField({onChange, value}) {
  const [selectedOption, setSelectedOption] = useState([]);

  const [guardianList, setGuardianList] = React.useState([])
  const goVehisService = useGoVehisService();

  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () => guardianList.filter((option) => containsText(option.name, searchText) || containsText(option.code, searchText)),
    [searchText, guardianList]
  );


  useEffect(() => {
    goVehisService.get("/admin/guardians").then(d => setGuardianList(d.data))
  }, []);


  return (
    <FormControl fullWidth  variant="outlined">
      <InputLabel>Opiekuni</InputLabel>
      <Select
        multiple
        // Disables auto focus on MenuItems and allows TextField to be in focus
        MenuProps={{ autoFocus: false }}
        value={value}
        renderValue={selected => {
          if (selected.length === 0) {
            return <em>Wybierz</em>;
          }
          // This will return a comma-separated list of the values.
          return selected.join(", ");
        }}
        onChange={e => onChange(e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
        label="Opiekuni oferty"
          onClose={() => setSearchText("")}
      >
        <ListSubheader>
          <TextField
            size="small"
            // Autofocus on textfield
            autoFocus
            placeholder="Szukaj"
            fullWidth
            onChange={(e) => setSearchText( e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {displayedOptions.map((option, i) => (
          <MenuItem key={i} value={option.code}>
            {option.code} - {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
