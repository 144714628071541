import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from "@material-ui/core/Snackbar";
import {useGoVehisService} from "../../../utils/axios";
import DeleteSingleOfferDialog from "../DeleteSingleOfferDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./VehicleOfferTable.css"
import {
  Box, Button,
  CardContent,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  Link, ListItemText,
  OutlinedInput,
  Select,
  TextField
} from "@material-ui/core";
import FormatUtils from "../../../utils/FormatUtils";
import TableToolbar from "./TableToolbar";
import TableHeader from "./TableHeader";
import EditVehicleOfferButton from "../EditVehicleOfferButton";
import OwnerName from "./OwnerName";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ActiveIcon from "./ActiveIcon";
import {SearchOptionsContext} from "../../../context/search-options-context";
import DuplicateVehicleOffer from "../DuplicateVehicleOffer";
import OfferState from "./OfferState";
import AkolSellerOfferButton from "../AkolSellerOfferButton";
import Typography from "@material-ui/core/Typography";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import MenuItem from "@material-ui/core/MenuItem";
import {stringify} from "uuid";
import GuardianSelectField from "./GuardianSelectField";
import CreatorSellerOfferButton from '../CreatorSellerOfferButton';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750,
  }
}));

const initialFilter = {
  query: "",
  calculationType: [],
  priceFrom: "",
  priceTo: "",
  supplier: "",
  guardian: [],
  status: null
};

export default function VehicleOfferTable({searchQuery, reload = true, onReloaded}) {
  const classes = useStyles();
  const [orderType, setOrderType] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [vehicleOffers, setVehicleOffers] = useState([]);
  const [vehicleOffersLoading, setVehicleOffersLoading] = useState(false);
  const [totalRows, setTotalRows] = React.useState(0);
  const [connError, setConnError] = React.useState(false);
  const [filtersVisible, setFiltersVisible] = React.useState(true);
  const goVehisService = useGoVehisService();
  const searchOptions = useContext(SearchOptionsContext);
  const [filter, setFilter] = React.useState(initialFilter);

  const offerSearchParameters = () => {
    let urlParams = new URLSearchParams();
    urlParams.set("offset", page * rowsPerPage);
    urlParams.set("rowCount", rowsPerPage);
    urlParams.set("orderBy", orderBy);
    urlParams.set("orderType", orderType.toUpperCase());
    if(Number.isInteger(filter.priceFrom)) { urlParams.set("priceFrom", filter.priceFrom);}
    if(Number.isInteger(filter.priceTo)) { urlParams.set("priceTo", filter.priceTo);}
    if(filter.calculationType !== null && filter.calculationType.length > 0 ) { urlParams.set("calculationType", filter.calculationType.join('|'))}
    if(filter.supplier !== null) {urlParams.set("supplier", filter.supplier);}
    if(filter.guardian !== null) {urlParams.set("guardian", filter.guardian);}
    if(filter.status !== null) {urlParams.set("status", filter.status);}
    if(filter.query !== null) {urlParams.set("query", filter.query);}

    return urlParams.toString();
  }

  const switchFilterVisible = () => setFiltersVisible(!filtersVisible);


  const clearFilters = () => {
    setFilter(initialFilter);
  }
  const getOffers = () => {
    setVehicleOffersLoading(true);
    goVehisService.get("admin/offers?" + offerSearchParameters())
      .then(response => {
        setVehicleOffers(response.data);
      })
      .catch((error) => {
        if (error.toString() === 'Error: Network Error') {
          setConnError(true);
          console.log('An error has occurred in connection with the application server' + error);
        }
      }).finally(() => {
      setVehicleOffersLoading(false);
      onReloaded();
    })
  }

  const getOffersCounter = () => {
    goVehisService.get("admin/offerscounter?" + offerSearchParameters())
      .then(response => {
        setTotalRows(response.data[0]);
      })
  }


  useEffect(() => {
    getOffers();
    setPage(0);
  }, [searchQuery])

  useEffect(() => {
    getOffersCounter();
  }, [vehicleOffers])

  useEffect(() => {
    getOffers();
  }, [page, rowsPerPage, orderBy, orderType])

  useEffect(() => {
    if (reload) {
      getOffers();
    }
  }, [reload])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && orderType === 'asc';
    setOrderType(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = vehicleOffers.map((n) => n.vehicleId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteSelectedItemsHandler = () => {
    setSelected([]);
    getOffers();
  }

  const activeIconClickHandler = (vehicleId) => {
    goVehisService.put(`/admin/switchVehicleOfferActivity?vehicleId=${vehicleId}`).then(response => {
      const offersBuf = new Array();

      vehicleOffers.forEach(offer => {
        if(offer.vehicleId === vehicleId){
          offer.isActive = response.data
        }

        offersBuf.push(offer)
      })

      setVehicleOffers(offersBuf)
    })
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows = 0

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={connError}
        autoHideDuration={1000}
        message="Błąd połączenia z serwerem"
      />
      <TableToolbar
        numSelected={selected.length}
        selectedOffers={selected}
        onChange={getOffers}
        onDeleted={deleteSelectedItemsHandler}
        showFilters={switchFilterVisible}
      />
      <Collapse in={filtersVisible}>
        <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4}><TextField fullWidth label="Wyszukaj ofertę po ID, marce, wersji lub VIN" placeholder="Wyszukaj ofertę po ID, marce, wersji lub VIN" variant="outlined"  onChange={e => setFilter({...filter, query: e.target.value})} /></Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={1}><CurrencyTextField fullWidth label="Cena od" variant="outlined" decimalCharacter="," digitGroupSeparator=" " currencySymbol="PLN" value={filter.priceFrom} onChange={e => setFilter({...filter, priceFrom: parseFloat(e.target.value.replace(',', '.').replace(' ', ''))})} /></Grid>
              <Grid item xs={1}><CurrencyTextField fullWidth label="Cena do" variant="outlined" decimalCharacter="," digitGroupSeparator=" " currencySymbol="PLN" value={filter.priceTo} onChange={e => setFilter({...filter, priceTo: parseFloat(e.target.value.replace(',', '.').replace(' ', ''))})} /></Grid>
              <Grid item xs={2}><FormControl fullWidth variant="outlined">
                <InputLabel>Forma finansowania</InputLabel>
                <Select renderValue={(selected) => selected.map(e => searchOptions.calculationTypeName[e]).join(', ')} multiple value={filter.calculationType} onChange={e => setFilter({...filter, calculationType: e.target.value})} input={<OutlinedInput label="Forma finansowania"/>} >
                  {Object.entries(searchOptions.calculationTypeName).map(e => (
                    <MenuItem key={e[0]} value={e[0]}><Checkbox checked={filter.calculationType.indexOf(e[0]) > -1} /><ListItemText primary={e[1]} /></MenuItem>
                    ))}
                </Select>
              </FormControl></Grid>
              <Grid item xs={2}><TextField fullWidth label="Dostawca" variant="outlined" value={filter.supplier} onChange={e => setFilter({...filter, supplier: e.target.value})} /></Grid>
              <Grid item xs={2}>
                <GuardianSelectField onChange={e => setFilter({...filter, guardian: e})} value={filter.guardian}/>
              </Grid>
              <Grid item xs={2}><FormControl fullWidth variant="outlined">
                <InputLabel>Status ogłoszenia</InputLabel>
                <Select label="Status ogłoszenia" defaultValue={null} value={filter.status} onChange={e => setFilter({...filter, status: e.target.value})}>
                  <MenuItem value={null}>Wszystkie</MenuItem>
                  <MenuItem value={0}>Nieaktywne</MenuItem>
                  <MenuItem value={1}>Aktywne</MenuItem>
                </Select>
              </FormControl></Grid>
              <Grid item xs={1}><Button variant="outlined" color="primary" fullWidth size="large" className="fullHeight" onClick={getOffers}>Szukaj</Button></Grid>
              <Grid item xs={1}><Button variant="outlined" color="secondary" fullWidth size="large" className="fullHeight" onClick={clearFilters}>Wyczyść</Button></Grid>
            </Grid>
        </CardContent>
      </Collapse>
      {vehicleOffersLoading ?
        <Box display="flex" justifyContent="center" my={5}><CircularProgress color="inherit"/></Box> :
        <TableContainer>


          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <TableHeader
              classes={classes}
              numSelected={selected.length}
              order={orderType}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={vehicleOffers.length}
            />
            <TableBody>
              {vehicleOffers.map((row, index) => {
                const isItemSelected = isSelected(row.vehicleId);
                const labelId = `enhanced-table-checkbox-${index}`;
                const offerLink = process.env.REACT_APP_GO_AUTO_URL + "/a/b/" + row.vehicleId + "?s=" + row.ownerCode;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.vehicleId}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) => handleClick(event, row.vehicleId)}
                        checked={isItemSelected}
                        inputProps={{'aria-labelledby': labelId}}
                      />
                    </TableCell>
                    <TableCell align="center" onClick={() => activeIconClickHandler(row.vehicleId)}><ActiveIcon active={row.isActive}/></TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      <Link href={offerLink} target="_blank">{row.vehicleId} <OpenInNewIcon
                        style={{fontSize: 15}}/></Link>
                    </TableCell>
                    <TableCell align="center">{row.source}</TableCell>
                    <TableCell align="center">{row.brandName}</TableCell>
                    <TableCell align="center">{row.versionName}</TableCell>
                    <TableCell align="center">{searchOptions.condition[row.condition]}</TableCell>
                    <TableCell align="center">{row.bodyType}</TableCell>
                    <TableCell align="center">{FormatUtils.formatMoney(row.minimumSellPriceNet)}</TableCell>
                    <TableCell align="center">{searchOptions.calculationTypeName[row.calculationType]}</TableCell>
                    <TableCell align="center">{row.ownerCode !== null && <OwnerName code={row.ownerCode}/>}</TableCell>
                    <TableCell align="center"><OfferState offer={row} onChange={getOffers}/></TableCell>
                    <TableCell align="center">{row.createdAt.slice(0, -7)}</TableCell>
                    <TableCell align="center">
                      <Grid container>
                        <Grid item xs={12}>
                          {row.source === 'AKOL' && <AkolSellerOfferButton data={row} onSaved={getOffers}/>}
                          {row.source === 'MAZUREK' && <AkolSellerOfferButton data={row} onSaved={getOffers}/>}
                        </Grid>
                          {row.editable && <Grid item xs={3}>{row.source === 'ADMIN' && <EditVehicleOfferButton data={row} onSaved={getOffers}/>}</Grid>}
                          {row.editable && <Grid item xs={3}>{row.source === 'ADMIN' && <DuplicateVehicleOffer data={row} onSaved={getOffers}/>}</Grid>}
                          {row.editable && <Grid item xs={3}>{row.source === 'ADMIN' && <DeleteSingleOfferDialog data={row} onDeleted={getOffers}/>}</Grid>}
                          {<Grid item xs={3}>{row.source === 'ADMIN' && <CreatorSellerOfferButton code={row.ownerCode} onDeleted={getOffers}/>}</Grid>}
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{height: (dense ? 33 : 53) * emptyRows}}>
                  <TableCell colSpan={6}/>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      }
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        backIconButtonText="Poprzednia strona"
        nextIconButtonText="Następna strona"
        labelRowsPerPage="Wiersze na stronę"
        labelDisplayedRows={({from, to, count}) => `${from}-${to} z ${count === -1 ? '' : `${count}`}`}
      />
    </>
  );
}
