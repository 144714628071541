import {useGoVehisService} from "../../utils/axios";
import {useEffect, useState} from "react";
import {Table, TableBody} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";

function AkolLogsList() {
  const goVehisService = useGoVehisService();
  const [logs, setLogs] = useState([])

  useEffect(() => {
    goVehisService.get("/admin/akol/logs").then(
      res => setLogs(res.data)
    )
  }, [])

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Id</TableCell>
          <TableCell>Komunikat</TableCell>
          <TableCell>Czas</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          logs.map((log) => (
            <TableRow key={log.id}>
              <TableCell>{log.id}</TableCell>
              <TableCell>{log.message}</TableCell>
              <TableCell>{new Date(log.time).toLocaleString()}</TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  )
}

export default AkolLogsList;
