import {Helmet} from "react-helmet";
import React from "react";
import {Box, Container} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import AkolDealerList from "../components/akol/AkolDealerList";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  }
}));

function AkolDealers() {

  const classes = useStyles();

  return (
    <div>
      <Helmet>
        <title>Dealerzy Akol | GO-auto - Panel administracyjny</title>
      </Helmet>
      <Box
        style={{
          minHeight: '100%'
        }}
      >
        <Container maxWidth={false}>
          <Box pt={1}>
            <Paper className={classes.paper}>
              <AkolDealerList/>
            </Paper>
          </Box>
        </Container>
      </Box>
    </div>
  )
}

export default AkolDealers;
