import {Helmet} from "react-helmet";
import React from "react";
import {Box, Container} from "@material-ui/core";
import VehicleOffersListToolbar from "../components/offer/VehicleOffersListToolbar";
import Paper from "@material-ui/core/Paper";
import VehicleOfferTable from "../components/offer/table/VehicleOfferTable";
import {makeStyles} from "@material-ui/core/styles";
import AkolLogsList from "../components/akol/AkolLogsList";
import AttributesList from "../components/config/attributes/AttrubutesList";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  }
}));

function ConfigAttributes() {

  const classes = useStyles();

  return (
    <div>
      <Helmet>
        <title>Matryca modeli | GO-auto - Panel administracyjny</title>
      </Helmet>
      <Box
        style={{
          minHeight: '100%'
        }}
      >
        <Container maxWidth={false}>
          <Box pt={1}>
            <Paper className={classes.paper}>
                <AttributesList />
            </Paper>
          </Box>
        </Container>
      </Box>
    </div>
  )
}

export default ConfigAttributes;
