import {useGoVehisService} from "../../utils/axios";
import {useEffect, useState} from "react";
import {Badge, Chip, Table, TableBody} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";

function AkolDealerList() {
  const goVehisService = useGoVehisService();
  const [logs, setLogs] = useState([])

  function reload() {
    goVehisService.get("/admin/akol/dealers").then(
      res => setLogs(res.data)
    )
  }

  useEffect(() => {
    reload();
  }, [])

  function changeStatus(nip) {
    goVehisService.post("/admin/akol/dealers/" + nip + "/status")
      .then(d => reload());
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Nazwa</TableCell>
          <TableCell>NIP</TableCell>
          <TableCell>Miasto</TableCell>
          <TableCell>Telefon</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          logs.map((log) => (
            <TableRow key={log.nip}>
              <TableCell>{log.name}</TableCell>
              <TableCell>{log.nip}</TableCell>
              <TableCell>{log.city}</TableCell>
              <TableCell>{log.phone}</TableCell>
              <TableCell>{log.email}</TableCell>
              <TableCell>
                {log.isActive &&   <Chip label="Aktywny" color="primary" sx={{cursor: "pointer"}} onClick={() => changeStatus(log.nip)}/>}
                {!log.isActive &&   <Chip label="Nieaktywny" color="secondary"  sx={{cursor: "pointer"}} onClick={() => changeStatus(log.nip)}/>}
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  )
}

export default AkolDealerList;
