import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import {useGoVehisService} from "../../utils/axios";
import React, {useEffect, useState} from "react";
import TableHeader from "./table/TableHeader";
import Typography from "@material-ui/core/Typography";

const AkolSellerOfferDialog = ({isOpen = false, onClose, data = null}) => {

  const goVehisService = useGoVehisService();

  const [traderRows, setTraderRows] = useState([]);
  const [dealerRows, setDealerRows] = useState([]);




  useEffect(() => {
    if (isOpen === true) {
      goVehisService.get("/admin/offers/" + data.vehicleId + "/akolSeller")
        .then(res => res.data)
        .then(res => {
          if(res.dealerData != null) {
            setDealerRows(
              [
                {label: "Nazwa", value: res.dealerData.name},
                {label: "Miasto", value: res.dealerData.city},
                {label: "Nip", value: res.dealerData.nip},
                {label: "E-mail", value: res.dealerData.email},
                {label: "Telefon", value: res.dealerData.phone},
                {label: "Status", value: res.dealerData.isActive ? "Aktywny" : "Nieaktywny"},
              ]
            );
          }
          if(res.traderData != null) {
            setTraderRows(
              [
                { label: "Imię i nazwisko", value : res.traderData.fullName },
                { label: "E-mail", value : res.traderData.email },
                { label: "Telefon", value : res.traderData.phone },
                { label: "Telefon 2", value : res.traderData.phone2 },
              ]
            );
          }

        });
    }
  }, [isOpen]);

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={isOpen}>
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Dane komisu AKOL
      </DialogTitle>
      <DialogContent dividers>
        {
          dealerRows.length > 0 &&
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                {dealerRows.map((row) => (
                  <TableRow key={row.label}>
                    <TableCell component="th" scope="row">
                      <strong>{row.label}</strong>
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }

      </DialogContent>
      {
        traderRows.length > 0 &&
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Dane handlowca
        </DialogTitle> &&
        <DialogContent dividers>
          <TableContainer component={Paper}>
            <Typography variant="h6" id="tableTitle" component="div">
              Dane handlowca
            </Typography>
            <Table aria-label="simple table">
              <TableBody>
                {traderRows.map((row) => (
                  <TableRow key={row.label}>
                    <TableCell component="th" scope="row">
                      <strong>{row.label}</strong>
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </DialogContent>
      }


      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Zamknij
        </Button>
      </DialogActions>
    </Dialog>
  )

}

export default AkolSellerOfferDialog;
