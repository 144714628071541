import React, {useContext, useEffect, useState} from "react";
import {SearchOptionsContext} from "../../../context/search-options-context";
import TabPanel from "../../common/TabPanel";
import theme from "../../../theme";
import FormSection from "../../common/FormSection";
import {Grid, TextField} from "@material-ui/core";
import {Controller} from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import {ErrorMessage} from "@hookform/error-message";

const TechnicalDataTabPanel = ({data, tabValue, form}) =>{

  const searchOptions = useContext(SearchOptionsContext);

  const {control, formState: {errors}, watch} = form;

  const isEngineCapacityRequired = () => watch("fuelType") !== "Elektryczny";

  const getModelsForBrand = brandName => {
    const brand = searchOptions.brandAndModels.filter(item => item.name === brandName)[0];

    return brand ? brand.models : [];
  }

  const getOptionListForModels = (modelName, listName) => {
    const model = availableModels.filter(item => item.name === modelName)[0];

    return model ? model[listName] : [];
  }

  const [availableModels, setAvailableModels] = useState(getModelsForBrand(data.brandName));
  const [availableBodyTypes, setAvailableBodyTypes] = useState(getOptionListForModels(data.modelName, "bodyTypes"));
  const [availableSegments, setAvailableSegments] = useState(getOptionListForModels(data.modelName, "segments"));

  useEffect(() => {
    setAvailableModels(getModelsForBrand(data.brandName))
  }, [data.brandName])

  const updateModelOptions = modelName => {
    setAvailableBodyTypes(getOptionListForModels(modelName, "bodyTypes"));
    setAvailableSegments(getOptionListForModels(modelName, "segments"));
  }

  useEffect(() => {
    updateModelOptions(data.modelName);
  }, [data.modelName])

  return (
    <TabPanel value={tabValue} index={2} dir={theme.direction}>
      <FormSection label="Dane techniczne">
        <Grid container spacing={3}>

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="brandName"
              control={control}
              rules={{required: <span className="error">To pole jest wymagane!</span>}}
              defaultValue={data.brandName}
              render={({field}) => (
                <TextField
                  {...field}
                  select
                  label="Marka"
                  fullWidth
                  variant="outlined"
                  onChange={(event) => {
                    setAvailableModels(getModelsForBrand(event.target.value));
                    field.onChange(event.target.value);
                  }}
                >
                  {searchOptions.brandAndModels.map((option) => (
                    <MenuItem key={option.name} value={option.name}>{option.name}</MenuItem>
                  ))}
                </TextField>
              )}
            />
            <ErrorMessage errors={errors} name="brandName">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
          <Grid item xs={12} xs={12} sm={6} md={4}>
            <Controller
              name="modelName"
              control={control}
              rules={{required: <span className="error">To pole jest wymagane!</span>}}
              defaultValue={data.modelName}
              render={({field}) => (
                <TextField
                  {...field}
                  select
                  label="Model"
                  placeholder="Najpierw wybierz markę!"
                  fullWidth
                  variant="outlined"
                  onChange={(event) => {
                    updateModelOptions(event.target.value);
                    field.onChange(event.target.value);
                  }}
                >
                  {availableModels.length !== 0 ?
                    availableModels.map((option) => (
                      <MenuItem key={option.name} value={option.name}>{option.name}</MenuItem>
                    )) :
                    <MenuItem key="x" value="">Najpierw wybierz markę!</MenuItem>
                  }
                </TextField>
              )}
            />
            <ErrorMessage errors={errors} name="modelName">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="versionName"
              control={control}
              rules={{
                required: <span className="error">To pole jest wymagane</span>,
                maxLength: {
                  value: 255,
                  message: <span className="error">Maksymalnie 255 znaków!</span>
                }
              }}
              defaultValue={data.versionName}
              render={({field}) => (
                <TextField
                  {...field}
                  label="Nazwa wersji"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <ErrorMessage errors={errors} name="versionName">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="segment"
              control={control}
              rules={{required: <span className="error">To pole jest wymagane!</span>}}
              defaultValue={data.segment}
              render={({field}) => (
                <TextField
                  {...field}
                  select
                  label="Segment"
                  fullWidth
                  variant="outlined"
                >
                  {availableSegments.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </TextField>
              )}
            />
            <ErrorMessage errors={errors} name="segment">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="bodyType"
              control={control}
              rules={{required: <span className="error">To pole jest wymagane!</span>}}
              defaultValue={data.bodyType}
              render={({field}) => (
                <TextField
                  {...field}
                  select
                  label="Nadwozie"
                  fullWidth
                  variant="outlined"
                >
                  {availableBodyTypes.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </TextField>
              )}
            />
            <ErrorMessage errors={errors} name="bodyType">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="manufactureYear"
              control={control}
              rules={{
                required: <span className="error">To pole jest wymagane</span>,
                maxLength: {
                  value: 4,
                  message: <span className="error">Maksymalnie 4 znaki!</span>
                },
                minLength: {
                  value: 4,
                  message: <span className="error">Minimalnie 4 znaki!</span>
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: <span className="error">Dopuszczalne tylko liczby!</span>
                }
              }}
              defaultValue={data.manufactureYear}
              render={({field}) => (
                <TextField
                  {...field}
                  label="Rok produkcji"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <ErrorMessage errors={errors} name="manufactureYear">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="enginePowerHp"
              control={control}
              rules={{
                required: <span className="error">To pole jest wymagane</span>,
                maxLength: {
                  value: 4,
                  message: <span className="error">Maksymalnie 4 znaki!</span>
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: <span className="error">Dopuszczalne tylko liczby!</span>
                }
              }}
              defaultValue={data.enginePowerHp}
              render={({field}) => (
                <TextField
                  {...field}
                  label="Moc silnika(KM)"
                  fullWidth
                  type="number"
                  variant="outlined"
                />
              )}
            />
            <ErrorMessage errors={errors} name="enginePowerHp">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
          {isEngineCapacityRequired() &&
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="engineCapacity"
              control={control}
              rules={{
                required: <span className="error">To pole jest wymagane</span>,
                maxLength: {
                  value: 9,
                  message: <span className="error">Maksymalnie 9 znaków!</span>
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: <span className="error">Dopuszczalne tylko liczby!</span>
                },
                min: 0,
                max: 1000000
              }}
              defaultValue={data.engineCapacity}
              shouldUnregister={true}
              render={({field}) => (
                <TextField
                  {...field}
                  label="Pojemność silnika(cm3)"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <ErrorMessage errors={errors} name="engineCapacity">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p style={{color: "red"}} key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>}
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="gearboxType"
              control={control}
              rules={{required: <span className="error">To pole jest wymagane!</span>}}
              defaultValue={data.gearboxType}
              render={({field}) => (
                <TextField
                  {...field}
                  select
                  label="Skrzynia biegów"
                  fullWidth
                  variant="outlined"
                >
                  {searchOptions.gearboxTypes.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </TextField>
              )}
            />
            <ErrorMessage errors={errors} name="gearboxType">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="fuelType"
              control={control}
              rules={{required: <span className="error">To pole jest wymagane!</span>}}
              defaultValue={data.fuelType}
              render={({field}) => (
                <TextField
                  {...field}
                  select
                  label="Paliwo"
                  fullWidth
                  variant="outlined"
                >
                  {searchOptions.fuelTypes.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </TextField>
              )}
            />
            <ErrorMessage errors={errors} name="fuelType">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="range"
              control={control}
              rules={{
                pattern: {
                  value: /^[0-9]+$/,
                  message: <span className="error">Dopuszczalne tylko liczby!</span>
                }
              }}
              defaultValue={data.range}
              render={({field}) => (
                <TextField
                  {...field}
                  label="Zasięg"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <ErrorMessage errors={errors} name="range">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="driveType"
              control={control}
              rules={{required: <span className="error">To pole jest wymagane!</span>}}
              defaultValue={data.driveType}
              render={({field}) => (
                <TextField
                  {...field}
                  select
                  label="Napęd"
                  fullWidth
                  variant="outlined"
                >
                  {searchOptions.driveTypes.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </TextField>
              )}
            />
            <ErrorMessage errors={errors} name="driveType">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="color"
              control={control}
              rules={{
                required: <span className="error">To pole jest wymagane</span>,
                maxLength: {
                  value: 255,
                  message: <span className="error">Maksymalnie 255 znaków!</span>
                }
              }}
              defaultValue={data.color}
              render={({field}) => (
                <TextField
                  {...field}
                  label="Kolor"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <ErrorMessage errors={errors} name="color">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="numberOfDoors"
              control={control}
              rules={{required: <span className="error">To pole jest wymagane!</span>}}
              defaultValue={data.numberOfDoors}
              render={({field}) => (
                <TextField
                  {...field}
                  label="Liczba drzwi"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <ErrorMessage errors={errors} name="numberOfDoors">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="numberOfSeats"
              control={control}
              rules={{required: <span className="error">To pole jest wymagane!</span>}}
              defaultValue={data.numberOfSeats}
              render={({field}) => (
                <TextField
                  {...field}
                  label="Liczba siedzeń"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <ErrorMessage errors={errors} name="numberOfSeats">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label="Kody Eurotax">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="brandCode"
              control={control}
              rules={{
                maxLength: {
                  value: 255,
                  message: <span className="error">Maksymalnie 255 znaków!</span>
                }
              }}
              defaultValue={data.brandCode}
              render={({field}) => (
                <TextField
                  {...field}
                  label="Kod marki"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <ErrorMessage errors={errors} name="brandCode">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="modelCode"
              control={control}
              rules={{
                maxLength: {
                  value: 255,
                  message: <span className="error">Maksymalnie 255 znaków!</span>
                }
              }}
              defaultValue={data.modelCode}
              render={({field}) => (
                <TextField
                  {...field}
                  label="Kod modelu"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <ErrorMessage errors={errors} name="modelCode">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="versionCode"
              control={control}
              rules={{
                maxLength: {
                  value: 255,
                  message: <span className="error">Maksymalnie 255 znaków!</span>
                }
              }}
              defaultValue={data.versionCode}
              render={({field}) => (
                <TextField
                  {...field}
                  label="Kod wersji pojazdu"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <ErrorMessage errors={errors} name="versionCode">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
        </Grid>
      </FormSection>
    </TabPanel>
  );
}

export default TechnicalDataTabPanel;
