import React from 'react';
import '../../style.css';
import PersonIcon from "@material-ui/icons/Person";
import Tooltip from "@material-ui/core/Tooltip";
import VehicleOfferDialogWithLoadedData from "./VehicleOfferDialogWithLoadedData";
import {useGoVehisService} from "../../utils/axios";
import AkolSellerOfferDialog from "./AkolSellerOfferDialog";


function AkolSellerOfferButton({data, onSaved}) {

  const [isOpen, setIsOpen] = React.useState(false);

  const goVehisService = useGoVehisService();


  return (
    <div>
      <Tooltip title="Dane sprzedawcy" placement="left-start">
        <PersonIcon onClick={() => setIsOpen(true)} />
      </Tooltip>
      <AkolSellerOfferDialog
        data={data}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
}

export default AkolSellerOfferButton;
