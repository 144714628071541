import React, {useEffect, useState} from "react";
import {CircularProgress} from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import Tooltip from '@material-ui/core/Tooltip';
import { useGoVehisService } from '../../utils/axios';
import AuthorInfoDialog from './table/AuthorInfoDialog';

const CreatorSellerOfferButton = ({code}) => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [owner, setOwner] = useState(true);

  const goVehisService = useGoVehisService();

  const handleDialogClose = () => setIsDialogOpen(false);

  useEffect(() => {
    if (code) {
      goVehisService.get('/sellers/' + code)
        .then(response => {
          let sellerData = {...response.data};

          if(sellerData) {
            setOwner(sellerData);
          }
        })
        .catch(error => console.log("Cannot get seller"));
    }
  }, [code]);


  return (<>
      <Tooltip title="Dane autora" placement="left-start">
        <PersonIcon onClick={() => setIsDialogOpen(true)} />
      </Tooltip>
      <AuthorInfoDialog isOpen={isDialogOpen} owner={owner} onClose={handleDialogClose}/>
    </>);

}

export default CreatorSellerOfferButton;
