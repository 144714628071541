import {useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Box, Divider, Drawer, List, Typography} from '@material-ui/core';
import NavItem from './NavItem';
import {DriveEta, ExitToApp, Settings, ViewList, Delete, Stop} from "@material-ui/icons";
import {AuthContext} from "../context/auth-context";

const DashboardSidebar = ({onMobileClose, openMobile}) => {
  const location = useLocation();
  const {user} = useContext(AuthContext);

  const items = [
    {
      href: '/app/offers',
      icon: DriveEta,
      title: 'Oferty',
      condition: true
    },
    {
      href: '/akol/logs',
      icon: Settings,
      title: 'Logi AKOL',
      condition: user.roles.includes("ROLE_ADMIN")
    },
    {
      href: '/akol/dealers',
      icon: Settings,
      title: 'Dealerzy AKOL',
      condition: user.roles.includes("ROLE_ADMIN")
    },
    {
      href: '/conf/attributes',
      icon: ViewList,
      title: 'Matryca modeli',
      condition: user.roles.includes("ROLE_ADMIN")
    },
    {
      href: '/app/incorrectOffers',
      icon: Stop,
      title: 'Nieprawidłowe ofery',
      condition: user.roles.includes("ROLE_ADMIN")
    },
    {
      href: '/app/leasingMatrix',
      icon: Stop,
      title: 'Matryca leasing online',
      condition: user.roles.includes("ROLE_ADMIN")
    },
    {
      href: '/logout',
      icon: ExitToApp,
      title: 'Wyloguj',
      condition: true
    }
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
        p={2}
      >
        <Typography
          color="textPrimary"
          variant="h5"
        >
          {user.firstName} {user.lastName}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          Zalogowany
        </Typography>
      </Box>
      <Divider/>
      <Box p={2}>
        <List>
          {items.map((item) => (
            item.condition && <NavItem
            href={item.href}
            key={item.title}
            title={item.title}
            icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box style={{flexGrow: 1}}/>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          style: {
            width: 256
          }
        }}
      >
        {content}
      </Drawer>

    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default DashboardSidebar;
