import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";

const AuthorInfoDialog = ({isOpen = false, onClose, owner}) => {


  const rows = [
    { label: "Imię i nazwisko", value : owner.agentFirstName ? owner.agentFirstName + " " + owner.agentLastName : owner.firstName + " " + owner.lastName },
    { label: "E-mail", value : owner.agentFirstName ? owner.agentEmail : owner.email },
    { label: "Telefon", value : owner.agentFirstName ? owner.agentPhone : owner.phone },
    { label: "Oddział", value : owner.agentFirstName? owner.agentName : owner.name },
    { label: "Kod", value : owner.agentFirstName ? owner.agentCode : owner.code },
  ];


  return (
      <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={isOpen}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Dane opiekuna oferty
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.label}>
                    <TableCell component="th" scope="row">
                      <strong>{row.label}</strong>
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose} color="primary">
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>
      )

}

export default AuthorInfoDialog;
