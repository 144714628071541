import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {useEffect, useState} from "react";
import {useGoVehisService} from "../../utils/axios";

function IncorrectOfferList() {

  const goVehisService = useGoVehisService();
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    goVehisService.get("/admin/offers/incorrects").then(res => setOffers(res.data));
  }, []);

  return (<div>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Id oferty</TableCell>
          <TableCell>Marka</TableCell>
          <TableCell>Model</TableCell>
          <TableCell>Typ nadwozia</TableCell>
          <TableCell>Kod modelu</TableCell>
          <TableCell>Źródło</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {offers.map((offer) => (
            <TableRow key={offer.vehicleId}>
              <TableCell>{offer.vehicleId}</TableCell>
              <TableCell>{offer.brand}</TableCell>
              <TableCell>{offer.model}</TableCell>
              <TableCell>{offer.bodyType}</TableCell>
              <TableCell>{offer.modelCode}</TableCell>
              <TableCell>{offer.source}</TableCell>
            </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>)
}

export default IncorrectOfferList;
