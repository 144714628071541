import {Helmet} from "react-helmet";
import {Box, Container} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import IncorrectOfferList from "../components/offer/IncorrectOfferList";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  }
}));

function IncorrectOffers() {

  const classes = useStyles();

  return (<div>
    <Helmet>
      <title>Matryca modeli | GO-auto - Panel administracyjny</title>
    </Helmet>
    <Box
      style={{
        minHeight: '100%'
      }}
    >
      <Container maxWidth={false}>
        <Box pt={1}>
          <Paper className={classes.paper}>
            <IncorrectOfferList/>
          </Paper>
        </Box>
      </Container>
    </Box>
  </div>)
}

export default IncorrectOffers;
